            <template>
                <div style="width: 100%;background: #E3E4E7;display: flex;justify-content: center;min-height: 100%;overflow: hidden;">
                    <!--  左侧-->
                    <div style="width: 250px;max-height: 870px;background: #FFFFFF;padding-left: 2px;overflow: hidden">
                        <div style="width: 100%;text-align: center;height: 40px;">
                            <div style="font-size: 18px;font-weight: bold;padding-top: 15px;">知识仓库</div>
                        </div>
                    </div>
                    <!--  中间部分 -->
                    <div style="width:1300px;overflow-x: hidden;overflow-y: hidden;">
                        <div class="avatar-uploader" style="overflow: hidden">
                            <div style="width: 1280px;" >
                                <div style="margin: 0px 5px;background: #FFFFFF;overflow:auto;height: 845px;">
                                    <div style="width:1257px;float: left;">
                                        <div style="height: 40px;margin:10px 9px;border: none;">
                                            <el-input style="width:1240px;height: 40px;border: none;font-size: 26px;font-weight: bold;" v-model="title" placeholder="请输入文章标题（不超过50个字）" maxlength="50"></el-input>
                                        </div>
                                        <!--编辑器-->
                                        <div style="margin-left: 9px;">
                                            <div style="width:1240px;min-height:700px;">
                                                <Editor  style="border: 1px solid #999999;" :tinymce_height="'700'" :innerDrawer = "true" :value="content" resize="none" ref="ch" ></Editor>
                                            </div>
                                        </div>
                                        <!-- 底部按钮 -->
                                        <template>
                                            <label v-if="id > 0">
                                                <div @click="add(1)" style="width: 130px;height: 40px;margin: 20px 5px;float: right">
                                                    <el-button type="primary" class="el-icon-position">保存</el-button>
                                                </div>
                                            </label>
                                            <label v-else>
                                                <div @click="add(0)" style="width: 130px;height: 40px;margin: 20px 5px;float: right">
                                                    <el-button type="primary" class="el-icon-position">发布文章</el-button>
                                                </div>
<!--                                                <div style="width: 130px;height: 40px;float: right;margin-top: 20px;">-->
<!--                                                    <el-button type="info" class="el-icon-bottom">保存草稿</el-button>-->
<!--                                                </div>-->
                                            </label>
                                        </template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--  右侧部分 -->
                    <div style="background: #FFFFFF;max-height: 860px;width: 400px;margin-left: 5px;">
                        <el-button style="float:left;background: #2970FF;height: 34px;margin: 20px;" type="info" size="mini" @click="create_thumb_pic()" slot="reference">点击获取文章图片</el-button>
                        <el-dialog :close-on-click-modal="false" title="图片封面" :visible.sync="dialogShow" :style="{minHeight: '100px'}" center>
                            <div style="width: 100%;">
                                <div v-if="imgMsgErr.code > 0" style="height: 80px;">
                                    <template >
                                        <el-alert v-if="imgMsgErr.code == 1" type="success" title="温馨提示" :closable="false">{{imgMsgErr.msg}}</el-alert>
                                        <el-alert v-if="imgMsgErr.code == 2" type="warning" title="温馨提示" :closable="false">{{imgMsgErr.msg}}</el-alert>
                                        <el-alert v-if="imgMsgErr.code == 3" type="error" title="温馨提示" :closable="false">{{imgMsgErr.msg}}</el-alert>
                                    </template>
                                </div>

                                <div style="width: 25%;height: 150px;float: left;" v-for="(item,index) in cover_imgs" :key="index" @click="change_border(index)" :class="{'active':index==coverIndex}">
                                    <img style="width: 100%;height:100%;overflow: hidden;" :src="item">
                                </div>
                            </div>
                        </el-dialog>
                        <div style="height: 200px;">
                            <div style="width: 156px;height: 106px;margin-left: 7px;">
                                <el-upload style="margin-left: 12px;width: 156px;height: 106px;"  drag action="" :http-request="upload_img2" :show-file-list="false" v-if="cover == '' ">
                                    <i class="el-icon-plus" style="width: 20px;height: 20px;font-size: 20px;color: #2970FF;margin-top:40px;text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);font-weight: 400;"></i>
                                    <div style="font-size: 12px;color: #666666">点击上传封面</div>
                                </el-upload>
                            </div>
                            <div style="width: 170px;height: 106px;position: relative;margin-top: -30px;margin-left: 12px" v-if="cover != ''">
                                <img :src="cover" style="width: 156px;height: 106px;border-radius: 6px;object-fit: cover;">
                                <div style="background:#ff7575;color:#FFF;text-align:center;width:15px;line-height:20px;position: absolute;left: 140px;bottom: 87px; cursor:pointer;user-select: none;" @click="removeImg2()">
                                    <i class="el-icon-delete"></i>
                                </div>
                            </div>
                        </div>
                        <div style="width: 100%;border-top: 1px solid #BDBFC1;"></div>
                        <div style="min-height: 450px">
                            <!-- 资料库分类 -->
                            <div style="float: left;width: 100%;display: flex">
                                <div style="margin:20px 0 0 10px; font-size: 16px;float: left;width: 35%;">资料库分类</div>
                                <div class="data" style="float: left;margin-top: 15px;">
                                    <template >
                                        <el-radio-group v-model="organ">
                                            <el-radio :label="1">标准规范</el-radio>
                                            <el-radio :label="2">政策法规</el-radio>
                                            <el-radio :label="3" style="margin-top: 10px">项目案例</el-radio>
                                            <el-radio :label="4">文章专著</el-radio>
                                        </el-radio-group>
                                    </template>
                                </div>
                            </div>
                            <!--  付费阅读  -->
                            <div style="width: 100%;float: left;margin-top: 10px">
                                <div style="float: left;margin: 20px 10px;">
                                    <el-checkbox v-model="paytoread" :disabled="auth != 4">资料有偿</el-checkbox>
                                </div>
                                <div style="float: left;margin-top: 10px;width: 200px;">
                                  <div style="float: left;width: 135px;margin-left: 5px;">
                                    <el-input  placeholder="有偿金额"  prefix-icon="el-icon-coin" v-model="input_paytoread"  :disabled="!paytoread || auth != 4" @input="UpNumber"></el-input>
                                  </div>
                                  <div style="float: left;width: 55px;line-height: 50px;padding-left: 5px;font-size: 12px;color: #C1C1C1;">
                                    (单位:元)
                                  </div>
                                </div>
                            </div>
                            <!-- 内容分类 -->
                            <div style="width: 100%;float: left;">
                                <div style="margin:25px 10px; font-size: 16px;float: left"><span style="color: red">*</span>资料分类</div>
                                <div style="float: left;margin-left: 10px;margin-top: 15px;">
                                    <el-cascader style="width: 228px"  v-model="kwtype" placeholder="请选择分类"  :options="type_list"></el-cascader>
                                </div>
                            </div>
                            <!-- 推荐频道 -->
                            <div style="width:100%;margin-top: 10px;float: left">
                                <div style="margin:10px 0 0 10px;font-size: 16px;float: left;"><span style="color: red">*</span>推荐频道</div>
                                <div style="float: left;margin-left: 20px">
                                    <template>
                                        <el-select style="width: 228px" v-model="channelId" :disabled="auth !== 4" label="请选择频道" :value="0">
                                            <el-option v-for="item in channel_list" :key="item.id" :label="item.name" :value="item.id"></el-option>
                                        </el-select>
                                    </template>
                                </div>
                            </div>
                        <!-- 权限限制 -->
                            <div style="width: 100%;display: flex;margin: 25px 10px;float: left">
                                <div style="width: 20%;margin: 10px 0 20px 0;font-size: 16px;" ><span style="color: red">*</span>权限设置</div>
                                <div style="width: 70%;margin: 10px;">
                                    <el-radio-group v-model="auth" @change="authType">
                                        <el-radio :label="1" >仅自己可见</el-radio>
                                        <el-radio :label="2" >仅好友可见</el-radio>
                                        <el-radio :label="3" style="margin-top: 10px">仅粉丝</el-radio>
                                        <el-radio :label="4" style="margin-left: 30px;">全部可见</el-radio>
                                    </el-radio-group>
                                </div>
                            </div>
                            <div style="width: 100%;display: flex;margin: 0px 10px;">
                                <div style="width: 20%;margin: 10px 0 20px 0;font-size: 16px;" >文章来源</div>
                                <div style="width: 70%;margin: 10px;">
                                    <el-radio-group v-model="form" @change="clearInput">
                                        <el-radio :label="1">原创</el-radio>
                                        <el-radio :label="2">转载</el-radio>
                                    </el-radio-group>
                                </div>
                            </div>
                            <div style="margin-left: 10px;margin-bottom: 10px;">
                                <el-input style="width: 300px;" v-model="reprintedAddress" placeholder="请填写原文链接" :disabled="form === 1"></el-input>
                            </div>
                        </div>
                        <div style="width: 100%;border-top: 1px solid #BDBFC1;"></div>
                        <!-- 上传附件   -->
                        <el-button style="float:left;background: #2970FF;height: 34px;margin: 20px;" type="info" size="mini" @click="set_upload_file_open" >上传文章附件</el-button>
                        <div style="color: #999999;font-size: 14px;float: right;margin: 30px;">当前附件:<span style="color: #2970FF">{{files.length}}</span>个</div>
                        <!--传文件-->
                        <el-dialog :close-on-click-modal="false" :show-close=false :visible.sync="upload_file_open" width="398px">
                            <el-tabs v-model="file_tag_name" type="border-card">
                                <el-tab-pane label="电脑上传" name="pc" >
                                    <el-upload accept=".pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx"  class="upload_url" style="width: 330px;" action="" drag multiple :http-request="upload_file" :show-file-list="false">
                                        <i class="el-icon-upload"></i>
                                        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                                        <div class="format">仅限pdf、doc、docx、xls、xlsx等文件格式</div>
                                    </el-upload>
                                </el-tab-pane>
                                <el-tab-pane label="手机上传" name="mobile">
                                    <div style="padding:10px;color:#a6a6a6;line-height:20px;">
                                        <p>使用微信“扫一扫”打开小程序</p><P>在 百工宝小程序 中向“文件助手”发送文件</P>
                                    </div>
                                    <div style="text-align:center;">
                                        <img :src="upload_qrcode" style="width:150px;height:150px;">
                                    </div>
                                </el-tab-pane>
                            </el-tabs>
                            <div style="overflow: hidden; height:55px;line-height:20px;">
                                <div style="float:left;color:#acacac;margin-top: 18px">
                                    文件数:{{files.length}}
                                </div>
                                <el-button style="width: 80px;border-radius: 5px; margin: 10px 0 0 108px;" @click="cancelFiles">取消</el-button>
                                <el-button style="width: 80px;border-radius: 5px; background-color: #031F88; color: #fff; margin: 10px 16px 10px 20px" @click="upload_file_open = false">确定</el-button>
                            </div>
                            <div style="overflow:hidden;height: 100%">
                                <div class="video" v-for="(item,index) in files" :key="index" style="width:80px;height:100px;border-radius:3px; float:left; margin:5px; background-size:100%;position:relative;" :title="item.name">
                                    <div style="width:100%;height:calc(100% - 20px);background-color: rgba(0,0,0,.3);">
                                        <img :src="ossUrl+'img/file.png'" style="width:100%;height:100%;" />
                                    </div>
                                    <div style="height:20px;width:70px;margin: 0 5px;line-height: 20px;text-align: center;word-break:keep-all;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;">{{item.name}}</div>
                                    <div style="background:#ff7575;color:#FFF;text-align:center;width:20px;height:20px;line-height:20px;border-radius:10px; cursor:pointer;user-select: none;position:absolute;right:0;top:0;" @click="removeFile(index)">
                                        <i class="el-icon-close"></i>
                                    </div>
                                </div>
                            </div>
                        </el-dialog>
                    </div>
                    <el-dialog title="图片转化进度" :close-on-click-modal="false" :show-close=false :visible.sync="upload_file_loading" width="398px">
                        <el-progress :text-inside="true" :stroke-width="26" :percentage="progress"></el-progress>
                    </el-dialog>
                </div>
        </template>
<script>
import Editor from "@/components/common/Editor.vue"
export default {
    components: {
     Editor
    },
    computed: {
        //加载后马上执行
        user(){
            return this.$store.state.user;

        },
        is_login(){
            return this.$store.state.is_login
        },
    },
    data() {
        return {
           dialogShow :false,
           organ: 1,
           kwtype:'',
           channel_list:[],
           type_ids:"",
           type_list:[],
           thumb:"",
           title:"",
           content:"",
           id:"",
           spaceSize:0,
           cover_imgs:[],
            thumbs:[
                "https://baigongbao.oss-cn-beijing.aliyuncs.com/img/thumbnail1.jpg",
                "https://baigongbao.oss-cn-beijing.aliyuncs.com/img/thumbnail2.jpg",
                "https://baigongbao.oss-cn-beijing.aliyuncs.com/img/thumbnail3.jpg",
                "https://baigongbao.oss-cn-beijing.aliyuncs.com/img/thumbnail4.png"
            ],
            article:{},
            //话题
            top_topic_list:[],
            topic_like:"",
            topicname:'',
            topicid:'',
            channelId:"",
              //权限
            common_type_name:{
                "1":"私密",
                "2":"好友",
                "3":"粉丝",
                "4":"公开"
            },
            common_type:"4",
            name:4,
            isshow:true,
            activeIndex: '1',

            cover:"",
            coverIndex:0,
            paytoread:false,
            // 权限
            auth:4,
            // 附件
            files:[],
            upload_file_open: false,
            file_tag_name: 'pc',
            upload_qrcode:"https://baigongbao.oss-cn-beijing.aliyuncs.com/img/xcx_qrcode.jpg-80*80",
            // 付费下载附件
            paiddownload:false,
            input_paytoread:"",

            //图片进度
            upload_file_loading:false,
            images:[],
            totalImages: 0,
            progress:0,
            imgMsgErr:{
              code:0,
              msg:''
            },
            oss_url:[],

            // 文章来源
            form:1,
            reprintedAddress:''

        }
    },
    watch: {
        paytoread: function(newVal) {
            if (!newVal && this.input_paytoread) {
                this.input_paytoread = '';
            }
        }
    },
    mounted: function () {
         var that = this;
         this.id = this.$route.params.id;
         this.get_type_list();
         this.get_channel_list();
         this.get_top_topic_list();  //话题列表
         if(this.id > 0 ){
            this.getKnowledgeInfo();
         }
    },
  methods:{
      clearInput() {
          if (this.form === 1) {
              this.reprintedAddress = '';
          }
      },
      authType:function (){
          if (this.auth != 4){
              this.input_paytoread = '';
          }
      },
      UpNumber() {
          this.input_paytoread = this.input_paytoread.replace(/[^0-9]/g, "");
          if(this.input_paytoread <= 0){
              this.utils.msg("最少1元");
              this.input_paytoread = 1;
          }
      },
      /**
       * 上传附件事件
       */
      set_upload_file_open:function(){
          this.upload_file_open = true
      },
      //上传附件
      upload_file:function(params){
          var that = this;
          var file = params.file;
          that.openLoading('正在上传请稍后')
          that.utils.upload(file,function (url){
	          if (!url){
		          return false;
	          }
              console.log(url)
              that.files.push(url);
          })
      },
      // 取消文件上传
      cancelFiles() {
          this.files = [];
          this.upload_file_open = false;
      },
      //移除附件
      removeFile:function(index){
          var that = this;
          that.fmList.splice(index, 1);
          that.files.splice(index,1);
      },
      //监听选中封面样式
      change_border(index){
          this.coverIndex = index;
          this.cover = this.cover_imgs[index];
      },
     change_common_type(command) {
            console.log(command)
            this.name = command;
            this.common_type =   command;
        },
      //获取文章详情
      getKnowledgeInfo:function(){
        var that = this;
        this.newApi.getKnowledgeInfo({
            id:that.id
        }).then((res)=>{
            that.article = res.data;
            that.organ = res.data.type;
            that.form = res.data.form;
            that.auth = res.data.auth;

            that.title = res.data.title;
            that.common_type = res.data.auth;
            that.name = res.data.auth;

            that.cover = res.data.cover;
            that.content = res.data.content;
            // 分类
            that.kwtype = JSON.parse(res.data.kwTypeArr);
            //     频道
            that.channelId = res.data.channelId;
            // 附件
            that.files = res.data.enclosure;
            if (res.data.form != 1){
                that.reprintedAddress = res.data.reprintedAddress;
            }
        }).catch((err)=>{
            console.log(err)
        })
      },

      //获取话题列表
        get_top_topic_list:function(){
            var that = this;
            that.newApi.getTopTopicList({}).then((ret)=>{
                if(ret.isSuccess == 1){
                    that.top_topic_list = ret.data;
                }
            }).catch((err) => {
                console.log(err);
            })
        },

        handleCommand(command) {
             console.log(command);
             this.topicname = command.name;
             this.topicid = command.id;
        },

        //话题搜索及显示
        search_topic(){
            var that = this;
            if (that.topic_like !="") {
                let param = {};
                param.like = that.topic_like;
                that.newApi.getTopTopicList(param).then((ret)=>{
                    if(ret.isSuccess == 1){
                        that.top_topic_list = ret.data;
                    }
                }).catch((err) => {
                    console.log(err);
                })
            }else{
                that.get_top_topic_list();
            }
        },
         get_type_list(){
            var that = this;
            that.newApi.getTypeList({
                lv: 3,
            }).then((res)=>{
                that.type_list = res.data;
            }).catch((err)=>{
                console.log(err)
            })
         },
         get_channel_list:function(){
            var that = this;
            that.newApi.getChannelList({}).then((ret)=>{
                if(ret.isSuccess == 1){
                    var data = [];
                    for (let i = 0; i < ret.data.length; i++) {
                        if(ret.data[i].classid == 1){
                            data.push(ret.data[i]);
                        }
                    }
                    that.channel_list = data;
                }
                }).catch((err)=>{
                    console.log(err)
                })
            },
         //手动上传封面
         upload_img2:function(params){
             var file = params.file;
             var that = this;
             const isLt5M = file.size / 1024 / 1024 < 20
             if (!isLt5M) {
                 that.utils.err('上传文件大小不能超过 20MB!')
                 return false
             }
             that.utils.upload(file,function (url){
	             if (!url){
		             return false;
	             }
                 that.cover = url.url;
             })
          },
        //移除封面
        removeImg2:function(){
            var that = this;
            that.utils.confirm("移除这个封面？",function(){
                that.cover= "";
            })
        },

        // 上传按钮
        add:function (status) {
            var that = this;
            var params = {};
            // 1原创 2转载
            params.form = that.form;
            // 转载地址
            if (that.form == 2){
                if(that.reprintedAddress == ''){
                    this.utils.err('请输入转载地址');
                    return
                }else {
                    params.reprintedAddress = that.reprintedAddress
                }
            }
            // 标题
            params.title = that.title;
            if(params.title == ""){
                this.utils.err("标题不能为空");
                return false;
            }
            // 1朋友圈 2暂定
            params.formType = 2;
            // 内容
            let chil = that.$refs.ch;
            let content = chil.get_content();
            content = content.replace(/amp;/g,"");
            params.content = content;
            if(params.content == ""){
                that.utils.err("请填写内容");
                return false;
            }
            // 权限
            // params.auth = this.common_type;
            // if(params.auth < 4) {
            //     this.isshow=false;
            //     params.channelId = 0;
            // } else{
            //       this.isshow=true
            //       params.channelId = this.channelId;
            // }
            params.images = [];
            params.videos = [];
            // params.enclosure = [];
            // params.images = JSON.stringify(params.images);
            // params.videos = JSON.stringify(params.videos);
            // 附件
            console.log(that.files)
            params.enclosure = JSON.stringify(that.files);
            //  封面
            if(that.cover == ""){
                that.utils.err("请上传封面");
                return false;
            }
            params.cover = that.cover;
            // 是否开启评论
            params.isComment = 1;
            // 是否开启转发
            params.isReprinted = 1;
            // 话题
            params.topicId = that.topicid;
            // 频道
            params.channelId = that.channelId;
            // 所属分类数组
            if (that.kwtype == ""){
                that.utils.err("请选择资料分类")
                return
            }
            params.kwTypeArr = JSON.stringify(that.kwtype);
            // 类型
            params.type =  that.organ;

            params.bgbType = 6;

            params.spaceSize = 0;
            // 付费阅读
            // if (that.input_paytoread !== "") {
            //     const payToReadValue = parseFloat(that.input_paytoread);
            //     if (isNaN(payToReadValue) || payToReadValue <= 0) {
            //         that.utils.err("金额要大于零");
            //         return false;
            //     }
            //     params.paymentType = 1;
            //     params.payAmt = payToReadValue;
            // } else if (that.input_files !== "") {
            //     const filesValue = parseFloat(that.input_files);
            //     if (isNaN(filesValue) || filesValue <= 0) {
            //         that.utils.err("金额要大于零");
            //         return false;
            //     }
            //     params.paymentType = 2;
            //     params.payAmt = filesValue;
            // } else {
            //     // 免费
            //     params.paymentType = 0;
            //     params.payAmt = 0;
            // }
            // 有偿资料
            if(that.input_paytoread !== ''){
                if (that.input_paytoread == 0){
                    that.utils.err("有偿金额必须要大于0")
                    return
                }else {
                    params.payAmt = that.input_paytoread;
                    params.paymentType = 3
                }
            }else {
                params.payAmt = 0;
                params.paymentType = 0;
            }
            console.log(params)

            if (status == 0) {
                that.addknowledge(params);
            }else{
                that.saveKw(params);
            }
        },
        addknowledge(params){
            var that = this;
            params.auth = this.auth
            that.openLoading('正在发布请稍后')
            that.newApi.addknowledge(params).then((ret)=>{
                if(ret.isSuccess == 1){
                    that.utils.sus(ret.data);
                    that.$router.push({path:'/filebase/index'});
                }
            }).catch((err)=>{
                console.log(err)
            })
        },
        saveKw(params){
            params.id = this.id;
            params.auth = this.auth;
            var that = this;
            that.openLoading('正在发布请稍后')
            this.newApi.editKnowledge(params).then((ret)=>{
                if(ret.isSuccess == 1){
                    that.utils.sus(ret.data);
                    this.$router.go(-1);
                }
            }).catch((err)=>{
                console.log(err)
            })
        },
        //生成文章封面
        create_thumb_pic() {
           var that = this;
           that.oss_url = [];
           var imgReg = /<img.*?(?:>|\/>)/gi;
           var srcReg = /src=['"]?([^'"]*)['"]?/i;
           let chil = that.$refs.ch;
           var content = chil.get_content();
           content = content.replace(/amp;/g, "");
           var arr = content.match(imgReg);
           if (arr == null) {
               that.cover_imgs = that.thumbs;
               that.dialogShow = true;
           } else {
               var imgs = [];
               for (var i = 0; i < arr.length; i++) {
                   var src = arr[i].match(srcReg);
                   imgs.push(src[1]);
               }

               //开始替换
               if (imgs.length == arr.length) {
                   that.progress = 0;
                   that.upload_file_loading = true;
                   that.totalImages = imgs.length;
                   var contains = 0;
                   for (let i = 0; i < imgs.length; i++) {
                       if (imgs[i].includes("baigongbao")) {
                           contains++;
                           that.progress = Math.floor((contains / this.totalImages) * 100);
                           that.oss_url.push(imgs[i]);
                           if(i == imgs.length - 1){
                               that.loopOperation(imgs);
                           }
                       } else {
                           that.newApi.uploadThumbImg({url: imgs[i]}).then((res) => {
                               if (res.isSuccess == 1) {
                                   if (res.data.code == 1) {
                                       contains++;
                                       that.progress = Math.floor((contains / this.totalImages) * 100);
                                       that.oss_url.push(res.data.url);
                                       content = content.replace(imgs[i], res.data.url);
                                       let chil = that.$refs.ch;
                                       chil.set_content(content);
                                   }

                                   if(i == imgs.length - 1){
                                       that.loopOperation(imgs);
                                   }
                               }
                           })
                       }
                   }

               } else {
                   that.cover_imgs = imgs;
                   that.dialogShow = true;
               }
           }
       },

      loopOperation(imgs) {
          var that = this;
          setTimeout(function (){
              if(that.oss_url.length == imgs.length){
                  that.imgMsgErr.code = 1;
                  that.imgMsgErr.msg = "所有图片转化完毕";
              }else if(that.oss_url.length > 0 && that.oss_url.length < imgs.length){
                  that.imgMsgErr.code = 2;
                  that.imgMsgErr.msg= "部分图片转化失败,请手动上传失败封面";
              }else{
                  that.imgMsgErr.code = 3;
                  that.imgMsgErr.msg= "图片提供方拒绝提供图片资源,转化失败,请手动上传封面";
              }
              that.cover_imgs = that.oss_url;
              that.dialogShow = true;
              that.upload_file_loading = false;
          },1000)
      }
   }
}
</script>

<style scoped>
.data{
    margin:5px 0 0 20px;

}
.determine {
    width: 112px;
    height: 40px;
    background: #031F88;
    border-radius: 5px 5px 5px 5px;
    margin:180px 0 0 300px;
    display:flex;
    justify-content: center;
}
.determine_th {
    font-size: 16px;
    font-weight: 400;
    color: #FFFFFF;
    margin-top:10px;
    cursor:pointer;
    float:left
}
.avatar {
    width: 280px;
    height: 180px;
    display: block;
    float: left;
    margin-top: 10px;
    border-radius: 0px;
    margin-left: 105px;
    margin-top:-40px;
}
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    cursor: pointer;
    /*position: relative;*/
    overflow: hidden;
    float: left;
}
.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}
.el-dropdown {
    color: #000;
}
/deep/ .el-upload-dragger {
    width: 156px;
    height: 106px;
}
/deep/ .el-radio__label {
    font-size: 15px;
}
/deep/ .el-checkbox__label {
    font-size: 16px;
}
/deep/ .el-checkbox__inner {
    width: 16px;
    height: 15px;
}
/deep/ .upload_url .el-upload-dragger {
    width: 330px !important;
    height: 164px !important;
}
/deep/ .el-dialog__body {
    display: flex;
    flex-direction: column;
}
/deep/ .el-checkbox:last-of-type{
  margin-right:0px;
}

.active{
    box-shadow:0 0 5px 10px #409eff;
}
.editor-container {
    height: 300px; /* 设置固定高度 */
    resize: none; /* 禁用调节功能 */
}
</style>
